<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex align-self-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">My Loans applications</span>
          <v-spacer></v-spacer>
          <v-btn outlined color="green" @click="$router.push('/MySalaryAdvanceApplications')">Salary advance
            applications</v-btn>

          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                  offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fromDate" label="From Date" outlined class="rounded-xl" hide-details dense
                      v-bind="attrs" v-on="on" clearable></v-text-field>
                  </template>
                  <v-date-picker v-model="fromDate" no-title @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                  offset-y max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="toDate" label="To Date" outlined hide-details dense class="rounded-xl"
                      v-bind="attrs" v-on="on" clearable></v-text-field>
                  </template>
                  <v-date-picker v-model="toDate" no-title @input="menu2 = false"></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select v-model="status" :items="statusList" outlined dense hide-details class="rounded-xl"
                  label="Status" clearable>
                </v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center v-if="loanList.length > 0">
          <v-flex xs12 align-self-center>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Date</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Purpose</th>
                    <th><span>Advance Loan(Rs.)</span>
                    </th>
                    <th class="text-left">Additional Docs</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="text-left align-center" v-for="(item, i) in loanList" :key="i">
                    <td>
                      <span v-if="item.create_date" class="" style="font-family: kumbhMedium">{{
                        item.create_date.slice(0, 10) }}</span>
                    </td>
                    <td>
                      <span v-if="item.loanAmount" class="" style="font-family: kumbhMedium">{{ item.loanAmount
                      }}</span>
                    </td>
                    <td>
                      <span v-if="item.purposeOfLoan" class="" style="font-family: kumbhMedium">{{ item.purposeOfLoan
                      }}</span>
                    </td>

                    <!-- <td>
                      <v-chip-group v-if="item.loanDocument">
                        <v-chip
                          v-for="(item2, i) in item.loanDocument"
                          :key="i"
                          class=""
                          style="font-family: kumbhMedium"
                          ><span v-if="item2">
                            {{ item2 }}
                            &nbsp;
                            <span
                              v-if="
                                item2.split('.').pop() == 'pdf' ||
                                item2.split('.').pop() == 'docx'
                              "
                            >
                              <a target="_blank" :href="mediaURL + item2">
                                <v-btn color="green" rounded outlined x-small>
                                  View
                                </v-btn></a
                              ></span
                            >
                            <span v-else>
                              <v-btn
                                @click="(doc = item2), (dialog2 = true)"
                                color="green"
                                rounded
                                outlined
                                x-small
                              >
                                View
                              </v-btn>
                            </span>
                          </span></v-chip
                        >
                      </v-chip-group>
                    </td> -->
                    <!-- <td v-else>-
                    </td> -->

                    <td v-if="item.advanceLoanAmountExist">
                      <span v-if="item.advanceLoanAmountExist == true">{{
                        item.advanceLoanAmount
                      }}</span>
                    </td>
                    <td v-else>-</td>
                    <td v-if="item.loanDocument"><v-icon v-if="item.loanDocument.length > 0"
                        @click="(docdialog = true), (curitem = item)">mdi-file-outline</v-icon><v-icon v-else
                        title="No documents uploaded">mdi-file-remove-outline</v-icon></td>
                    <td>
                      <span v-if="item.status" class="" style="font-family: kumbhMedium">{{ item.status }}</span>
                    </td>
                    <td>
                      <span  v-if="item.status=='Pending' || item.status=='Rejected'">
                        <v-btn icon color="#005f32" @click="openEditDialog(item)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </span>
                    </td>
                    <!-- <td>
                      <v-layout wrap>
                        <v-flex xs6 sm4 md2 pa-2>
                          <v-dialog v-model="item.view" max-width="65%">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="#005f32"
                                style="font-family: kumbhBold"
                                small
                                dark
                                fab
                                v-on="on"
                                v-bind="attrs"
                                plain
                                depressed
                              >
                                <v-icon>mdi-eye</v-icon>
                              </v-btn>
                            </template>
      <v-card>
        <v-layout wrap>
          <v-flex xs12 align-self-center pa-4 style="background: #005f32" text-left>
            <span class="kumbhBold" style="color: #ffffff">Details</span>
          </v-flex>
        </v-layout>
        <v-layout wrap pa-4>
          <v-flex xs12 sm6 align-self-center text-left pa-2>
            <span v-if="item.create_date" class="" style="font-family: kumbhBold;color:#005f32;font-size:18px">{{
              item.create_date.slice(0, 10) }}</span>
          </v-flex>
          <v-flex xs12 sm6 align-self-center text-right pa-2>
            <span v-if="item.status" class="" style="font-family: kumbhBold;color:#005f32;font-size:18px">{{ item.status
              }}</span>
          </v-flex>
          <v-flex xs12 align-self-center text-left pa-2>
            <span>Amount (INR) : </span>

            <span v-if="item.amount" class="" style="font-family: kumbhMedium">{{ item.amount }}</span>
          </v-flex>

          <v-flex xs12 align-self-center text-left pa-2>
            <span>Type : </span>

            <span v-if="item.type" class="" style="font-family: kumbhMedium">{{ item.type }}</span>
          </v-flex>

          <v-flex xs12 align-self-center text-left pa-2>
            <span>Description : </span>

            <span v-if="item.description" class="" style="font-family: kumbhMedium">{{ item.description }}</span>
          </v-flex>
        </v-layout>
      </v-card>
      </v-dialog>
  </v-flex>
</v-layout>
</td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Items found!</span>
          </v-flex>
        </v-layout>
        <v-dialog persistent v-model="dialog2" width="70%">
          <v-card>
            <v-toolbar dense dark>
              <v-spacer></v-spacer>
              <v-btn right icon dark @click="(doc = null), (dialog2 = false)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-title class="text-h5 grey lighten-2">
              Document
            </v-card-title>
            <v-card-text v-if="doc">
              <v-img :src="mediaURL + doc"></v-img>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined small color="red" text @click="(dialog2 = false), (doc = null)">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="docdialog" width="70%">
          <v-card>
            <v-layout wrap>
              <v-flex xs12 pa-2 text-left>
                Supporting documents
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout wrap>
              <v-flex xs12 pa-4>
                <v-chip-group v-if="curitem.loanDocument">
                  <v-chip v-for="(item2, i) in curitem.loanDocument" :key="i" class=""
                    style="font-family: kumbhMedium"><span v-if="item2">
                      {{ item2 }}
                      &nbsp;
                      <span v-if="
                        item2.split('.').pop() == 'pdf' ||
                        item2.split('.').pop() == 'docx'
                      ">
                        <a target="_blank" :href="mediaURL + item2">
                          <v-btn color="green" rounded outlined x-small>
                            View
                          </v-btn></a></span>
                      <span v-else>
                        <v-btn @click="(doc = item2), (dialog2 = true)" color="green" rounded outlined x-small>
                          View
                        </v-btn>
                      </span>
                    </span></v-chip>
                </v-chip-group>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outlined small color="red" text @click="(docdialog = false)">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
            v-model="currentPage" color="#005f32"></v-pagination>
        </div>
      </v-flex>
    </v-layout>

    <v-dialog persistent v-model="editdialog" max-width="700px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Edit Loan Application</span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="editdialog = false, getData()">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field hide-details dense outlined  v-model="editingItem.loanAmount" label="Amount"
                  type="number"></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field hide-details dense outlined v-model="editingItem.purposeOfLoan" label="Purpose"></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field dense hide-details outlined v-model="editingItem.advanceLoanAmount" label="Advance Loan (Rs.)"
                  type="number"></v-text-field> </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field hide-details dense outlined v-model="editingItem.programmeOrDivision"
                  label="Program/Division"></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field hide-details dense outlined v-model="editingItem.office" label="Office"></v-text-field> </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field hide-details dense outlined v-model="editingItem.tobeReleasedBy" label="Released By"></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2
                v-if="editingItem.loanDocument && editingItem.loanDocument.length">
                <v-card outlined class="pa-2">
                <span>Existing Documents:</span><br>
                <v-chip v-for="(doc, index) in editingItem.loanDocument" :key="index" class="ma-2 " close
                  @click:close="confirmDelete(doc, editingItem._id)">
                  <v-icon left>mdi-file</v-icon> {{ doc }}
                </v-chip>
                </v-card>
              </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <span style="font-size:15px;font-family:kumbhMedium">Upload supporting documents</span> </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-card outlined class="pa-2">

                  <v-flex xs1 align-self-center>



                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon large color="#005f32" v-bind="attrs" v-on="on"
                          @click="$refs.files.click()">mdi-cloud-upload</v-icon>
                      </template>


                      <!-- </v-flex>
               <v-flex xs1 pb-5> -->
                      <span class="con4">upload Document</span>
                 &nbsp;
                      <input v-show="false" id="file1" ref="files" type="file" multiple @change="browseCover" />
                    </v-tooltip>

                  </v-flex>
                </v-card> </v-flex>


              <v-flex xs12 sm12 align-self-center text-left pa-2 v-if="documentFile1.length > 0">
                <v-chip v-for="(item, i) in documentFile1" :key="i" color="green" class="ma-1" dark close
                  @click:close="removeDocument(i)" style="margin-right: 6px;">
                  {{ item.name }}
                </v-chip> </v-flex>


              <v-flex xs12 text-end pt-3 pa-2>
                <v-btn @click="saveEdit" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Save Changes </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>


    <!-- <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this document?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="red" @click="deleteDocument" dark>Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->


    <v-dialog persistent v-model="deleteDialog" max-width="700px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Delete Document</span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="deleteDialog = false, getData()">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 text-start pa-4 class="itemHeading">
                Are you sure you want to delete this document?
              </v-flex>
              <v-flex xs12 text-end pt-3 pa-4>
                <v-btn @click="deleteDocument" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Delete</span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>



  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      show: false,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      deleteDialog: false,
      // editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      idd: null,
      loanList: [],
      data: [],
      //view doc
      curitem: [],
      docdialog: false,
      dialog2: false,
      doc: null,

      //
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      assetId: null,
      assetList: [],
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      status: null,

      statusList: ["Pending", "Approved", "Rejected"],
      employeeId: null,
      departmentId: null,
      search1: null,

      empList: [],
      deptList: [],
      quantity: null,

      editDialog: false,
      editingItem: {},
      documentFile1: [],
      docsdata: new FormData(),
      selectedDocument: null,
      selectedItemId: null



    };
  },
  components: {
    // ImageComp,
  },
  computed: {
    userId() {
      return this.$store.state.userData._id;
    },
  },
  watch: {
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    status() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
  },
  mounted() {
    this.getData();
    if (this.fromDate) {
      this.fromDate = null;
    }
    if (this.toDate) {
      this.toDate = null;
    }
    if (this.userId) {
      this.getData();
    }
  },

  methods: {
    //     openEditDialog(item) {
    //   this.editingItem = { ...item }; // Clone the object to avoid modifying original data
    //   this.editingItem.create_date = item.create_date.slice(0, 10); // Format for input display
    //   this.editdialog = true;
    // },
    openEditDialog(item) {
      this.editingItem = { ...item };
      // this.editingItem.create_date = item.create_date.slice(0, 10); 
      this.editdialog = true;
    },

    removeDocument(i) {
      this.documentFile1.splice(i, 1);
    },
    browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },

    saveEdit() {
      let data = {
        id: this.editingItem._id,
        employeeId: this.editingItem.employeeId,
        // create_date: new Date(this.editingItem.create_date).toISOString(),
        loanAmount: this.editingItem.loanAmount,
        purposeOfLoan: this.editingItem.purposeOfLoan,
        programmeOrDivision: this.editingItem.programmeOrDivision,
        advanceLoanAmount: this.editingItem.advanceLoanAmount,
        office: this.editingItem.office,
        tobeReleasedBy: this.editingItem.tobeReleasedBy,
      };

      axios.post("/loan/edit", data, {
        headers: { "x-auth-token": localStorage.getItem("token") },
      })
        .then((response) => {
          if (response.data.status) {
            this.msg = "Edited Successfully";
            this.showsnackbar = true;
            if (this.documentFile1.length > 0) {
              console.log("hiii");
              this.documentID = response.data.data._id;
              console.log("documentID", this.documentID);
              console.log("documentfile", this.documentFile1)
              if (this.documentID) {
                this.docsupload();
              } else {
                alert("document updated");
              }
            }
            else {
              this.getData();
              this.editdialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => console.error(err));
    },


    docsupload() {
      for (var i = 0; i < this.documentFile1.length; i++) {
        this.docsdata.append("document", this.documentFile1[i]);
      }
      this.docsdata.append("id", this.documentID);

      this.appLoading = true;
      axios({
        url: "/upload/loandocuments",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.docsdata,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            // this.dialog = false;
            this.documentFile1 = [];

            this.docsdata = new FormData();
            this.msg = response.data.msg;
            this.editdialog = false;
            this.getData();
          }
          else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },



    //  userdata() {
    //   this.appLoading = true;
    //   axios({
    //     method: "get",
    //     url: "/employee/profile/me",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status==true) {
    //         this.idd=response.data.data._id;
    //         console.log("idd=",this.idd)
    //         this.getData();
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
    getData() {
      this.appLoading = true;
      axios({
        // url: "/salaryAdvance_Loans/own/list",
        url: "/loan/getlist",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          status: this.status,
          employeeId: this.userId,
          page: this.currentPage,
          limit: 20,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.loanList = response.data.data;
          this.totalData = response.data.totalLength;
          console.log("userData", this.userId);
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    confirmDelete(doc, itemId) {
      this.selectedDocument = doc;
      this.selectedItemId = itemId;
      this.deleteDialog = true;
    },
    deleteDocument() {
      const data = {
        documentName: this.selectedDocument,
        loanid: this.selectedItemId
      };

      axios({
        url: "/loan/document/delete",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data
      })
        .then((response) => {
          this.deleteDialog = false;

          if (response.data.status) {
            // Remove the deleted document from the local list
            const index = this.editingItem.loanDocument.indexOf(this.selectedDocument);
            if (index !== -1) {
              this.editingItem.loanDocument.splice(index, 1);
            }

            this.msg = "Deleted Successfully";
            this.showsnackbar = true;

            // Fetch the updated list
            this.getData();
          }
          else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.deleteDialog = false;
          console.error("Error deleting document:", err);
        });
    },

    editcat(item) {
      this.editingitem = item;

      this.getEmp();
      // if (item.departmentId) this.departmentId = item.departmentId._id;
      // if (item.employeeId) this.employeeId = item.employeeId._id;
      this.editdialog = true;
    },
    validateEdit() {
      if (this.quantity > this.editingitem.quantity) {
        this.msg =
          "Quantity should be less than or same as already assigned quantity!";
        this.showsnackbar = true;
        return;
      } else if (!this.assignType) {
        this.msg = "Please provide assign type";
        this.showsnackbar = true;
        return;
      } else if (!this.departmentId) {
        this.msg = "Please choose a department";
        this.showsnackbar = true;
        return;
      } else if (this.assignType == "Employee" && !this.employeeId) {
        this.msg = "Please choose an employee";
        this.showsnackbar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      var data = {};
      data["inventoryStockId"] = this.editingitem._id;
      data["assignType"] = this.assignType;
      data["departmentId"] = this.departmentId;
      data["employeeId"] = this.employeeId;
      data["quantity"] = this.quantity;
      axios({
        url: "/asset/reassign/transfer",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            console.log("4");
            this.getData();
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    clearOff() {
      this.employeeId = null;
      this.assignType = null;
      this.quantity = null;
      this.departmentId = null;
    },
  },
};
</script>
